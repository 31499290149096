<template lang="pug">
  div.contact
    div.container
      h1 {{ $t('contact.title') }}
      .contact__form
        h4 {{ $t('contact.question') }}
        p {{ $t('contact.answer') }}
        form(@submit.prevent="sending")
          .contact__wrap
            label {{ $t('contact.firstName') }}
              input(
                :placeholder="$t('placeholder.firstName')"
                type="text"
                id="name"
                v-model="body.first_name"
                required
              )
            label {{ $t('contact.lastName') }}
              input(
                :placeholder="$t('placeholder.lastName')"
                type="text"
                v-model="body.last_name"
                required
              )
          label {{ $t('contact.email') }}
            input(
              placeholder="you@company.com"
              type="email"
              v-model="body.email"
              required
            )
          label.checkbox-form__label {{ $t('contact.phoneNumber') }}
          vue-phone-number-input(
            defaultCountryCode="US"
            v-model="phone_number"
            defaultCountry="US"
            required
            :countryCallingCode="2"
            @update="countryCall"
            :dark="true"
          )
          label {{ $t('contact.message') }}
            textarea(v-model="body.message")
          .checkbox-form
            .answers
              label.item
                span
                  div(v-html="$t('contact.agreeText')")
                input(
                  type="checkbox"
                  required
                ).check__module
                span.checkmark
          label
            input(
              type="submit"
              :value="$t('contact.sendMessage')"
            )
    PopupBlock(
      v-show="isShowPopup"
      @closePopup="isShowPopup = false"
    )
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import api from '@/plugins/api.js'

export default {
  name: 'ContactPage',
  metaInfo () {
    return this.$metaDefault()
  },
  components: {
    PopupBlock: () => import('@/components/PopupBlock.vue')
  },
  data () {
    return {
      isShowPopup: false,
      body: {
        first_name: '',
        last_name: '',
        email: '',
        message: ''
      },
      phone_number: '',
      countryCode: '',
      defaultCountryCode: 'US',
      defaultCountry: 'US'
    }
  },
  methods: {
    async sending () {
      this.body.phone_number = this.countryCode
      const result = await api.post(`api/v1/communication/${ process.env.VUE_APP_API_URL }/`, { body: this.body })
      if (result?.code === 201) {
        this.isShowPopup = true
        this.waitPopup()
        this.body.first_name = ''
        this.body.last_name = ''
        this.body.email = ''
        this.phone_number = ''
        this.body.message = ''
        document.querySelector('.check__module').checked = false
      }
    },
    countryCall (value) {
      this.countryCode = value.formattedNumber
    },
    waitPopup () {
      setTimeout(() => {
        this.isShowPopup = false
      }, 4000)
    }
  }
}
</script>
